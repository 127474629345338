import React from 'react';
import { Link } from 'gatsby';
import Icon from '@mdi/react';
import { mdiLinkedin } from '@mdi/js';
import logo from '../../images/logos/vertical.svg';

const Footer = () => {
  return (
    <footer
      className="footer p-section text-left pl-2 pr-2"
      style={{ color: 'white', fontSize: '12px' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div style={{ maxWidth: 240 }}>
              <img
                src={logo}
                alt="logo"
                style={{ width: 120, marginBottom: 20, marginTop: -22, marginLeft: -10 }}
              />
              <div>
                <div className="divider-sm float-left" />
              </div>
              <p className="mt-2 pr-2" style={{ lineHeight: '24px', color: 'white' }}>
                Creamos proyectos, aplicando lo último en innovación y materiales, el conocimiento y
                la creatividad para favorecer y solucionar las necesidades de todos nuestros
                proyectos.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row mb-3">
              <div className="col">
                <div style={{ height: 4 }}>
                  <div className="divider-sm float-left" />
                </div>
                <div className="super-title" style={{ fontSize: 14, marginTop: 2 }}>
                  Encuentra
                </div>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link to="/">Inicio</Link>
                  </li>
                  <li>
                    <Link to="/#prev-nosotros">Nosotros</Link>
                  </li>
                  <li>
                    <Link to="/proyectos">Proyectos</Link>
                  </li>
                  <li>
                    <Link to="/contacto">Contacto</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div style={{ height: 4 }}>
                  <div className="divider-sm float-left" />
                </div>
                <div className="super-title" style={{ fontSize: 14, marginTop: 2 }}>
                  Especialidades
                </div>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link to="/proyectos?#proyectos">Sector Industrial</Link>
                  </li>
                  <li>
                    <Link to="/proyectos?#proyectos">Hoteles y Retail</Link>
                  </li>
                  <li>
                    <Link to="/proyectos?#proyectos">Concesionarios</Link>
                  </li>
                  <li>
                    <Link to="/proyectos?#proyectos">Oficinas</Link>
                  </li>
                  <li>
                    <Link to="/proyectos?#proyectos">Gasolineras</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div style={{ height: 4 }}>
                  <div className="divider-sm float-left" />
                </div>
                <div className="super-title" style={{ fontSize: 14, marginTop: 2 }}>
                  Localización
                </div>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link to="/contacto">Madrid</Link>
                  </li>
                  <li>
                    <Link to="/contacto">España</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div style={{ height: 4 }}>
                  <div className="divider-sm float-left" />
                </div>
                <div
                  className="super-title"
                  style={{ fontSize: 14, marginTop: 2, marginBottom: 6 }}
                >
                  Connect
                </div>
                <a
                  style={{ color: '#fff' }}
                  href="https://www.linkedin.com/company/ingelux-ingenier%C3%ADa-e-iluminaci%C3%B3n/"
                >
                  <Icon path={mdiLinkedin} title="Linked In" size={0.8} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
